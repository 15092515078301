/* eslint-disable no-useless-constructor */
import feathersClient, { BaseModel, makeServicePlugin } from "../../feathers-client";

class ERCOTNode extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "ERCOTNode";
}

const servicePlugin = makeServicePlugin({
  Model: ERCOTNode,
  service: feathersClient.service("data-services/ercot/buses"),
  idField: "Bus Name",
  nameStyle: "path",
  getters: {},
});

export default servicePlugin;
