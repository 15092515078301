/* eslint-disable no-useless-constructor */
import feathersClient, { BaseModel, makeServicePlugin } from "../feathers-client";

class Directories extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "Directories";
}

const servicePlugin = makeServicePlugin({
  Model: Directories,
  service: feathersClient.service("directories"),
  paramsForServer: ["copy"],
  extend() {
    return {
      actions: {
        confirmRemove({ dispatch }, id) {
          const itemId = id;
          if (window.confirm("Are you sure you want to delete this folder and all its elements?")) {
            return dispatch("remove", itemId);
          }
          return false;
        },
      },
    };
  },
});

export default servicePlugin;
