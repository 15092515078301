<template>
  <div id="app">
    <component :is="layout" v-bind="layoutProps">
      <router-view />
    </component>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
  computed: {
    route() {
      return this.$route;
    },
    layout() {
      return this.$route.meta.layout ? `${this.$route.meta.layout}Layout` : "defaultLayout";
    },
    layoutProps() {
      return {
        ...(this.$route.meta.error ? { error: this.$route.meta.error } : {}),
      };
    },
  },
  methods: {},
};
</script>
