/* eslint-disable no-useless-constructor */
import feathersClient, { BaseModel, makeServicePlugin } from "../../feathers-client";

class marketInventory extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "marketInventory";
}

const servicePlugin = makeServicePlugin({
  Model: marketInventory,
  service: feathersClient.service("data-services/market-inventory"),
  nameStyle: "path",
  id: "_id",
});

export default servicePlugin;
