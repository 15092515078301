/* eslint-disable no-useless-constructor */
import feathersClient, { BaseModel, makeServicePlugin } from "../feathers-client";

class NYISONodes extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "NYISONodes";
}

const servicePlugin = makeServicePlugin({
  Model: NYISONodes,
  service: feathersClient.service("data-services/nyiso/nodes"),
  idField: "PTID",
  nameStyle: "path",
  getters: {},
});

export default servicePlugin;
