/* eslint-disable no-useless-constructor */
/* eslint-disable no-alert */

import feathersClient, { BaseModel, makeServicePlugin } from "../feathers-client";

class Forecasts extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "Forecasts";
}

const servicePlugin = makeServicePlugin({
  Model: Forecasts,
  service: feathersClient.service("forecasts"),
  actions: {
    confirmRemove({ dispatch }, id) {
      const itemId = id;
      if (window.confirm("Are you sure you want to delete this forecast?")) {
        return dispatch("remove", itemId);
      }
      return false;
    },
  },
});

export default servicePlugin;
