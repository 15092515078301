/* eslint-disable consistent-return */
import store from "@/store";
import { getInstance } from "./index";

export default (to, from, next) => {
  const authService = getInstance();

  const guardAction = async () => {
    if (authService.isAuthenticated) {
      // Verify if user has license
      let user = await store.dispatch("users/find", {
        query: { user_id: authService.user.sub },
      });
      [user] = user.data;

      // Set acelerex user ID to auth user
      authService.user.id = user.id;

      const licenseTypes = user.licenses.map((l) => l.type.trim().toUpperCase());
      if (!licenseTypes.includes(`ANALYTICS_USER_V${process.env.VUE_APP_VERSION.split(".")[0]}`)) {
        const error = new Error("No Analytics license found");
        error.name = "Forbidden";
        next(error);
      }
      return next();
    }

    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  if (authService.loading === false) {
    return guardAction();
  }

  authService.$watch("loading", (loading) => {
    if (loading === false) {
      return guardAction();
    }
  });
};
