/* eslint-disable no-useless-constructor */
import feathersClient, { BaseModel, makeServicePlugin } from "../feathers-client";

class OpportunityZones extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "OpportunityZones";
}

const servicePlugin = makeServicePlugin({
  Model: OpportunityZones,
  service: feathersClient.service("opportunity-zones"),
  getters: {},
});

export default servicePlugin;
