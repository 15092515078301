/* eslint-disable no-useless-constructor */
/* eslint-disable no-alert */

import feathersClient, { BaseModel, makeServicePlugin } from "../feathers-client";

class Profile extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "Profiles";
}

const servicePlugin = makeServicePlugin({
  Model: Profile,
  service: feathersClient.service("profiles"),
  instanceDefaults(data) {
    const profile = data;
    if (typeof profile.value === "string") {
      profile.value = JSON.parse(profile.value);
    }
    return profile;
  },
  getters: {},
});

export default servicePlugin;
