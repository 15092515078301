/* eslint-disable no-useless-constructor */
import feathersClient, { BaseModel, makeServicePlugin } from "../../feathers-client";

class SPPNode extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "SPPNode";
}

const servicePlugin = makeServicePlugin({
  Model: SPPNode,
  service: feathersClient.service("data-services/spp/nodes"),
  idField: "Node",
  nameStyle: "path",
  getters: {},
});

export default servicePlugin;
