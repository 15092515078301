/* eslint-disable no-useless-constructor */
/* eslint-disable no-alert */

import feathersClient, { BaseModel, makeServicePlugin } from "../feathers-client";

class OutputProfiles extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "OutputProfiles";
}

const servicePlugin = makeServicePlugin({
  Model: OutputProfiles,
  service: feathersClient.service("output-profiles"),
  getters: {
    loadPending(state) {
      return state.isGetPending || state.isFindPending;
    },
  },
});

export default servicePlugin;
