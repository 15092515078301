/* eslint-disable no-unused-vars */
import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";
// import colors from 'vuetify/es5/util/colors';

const themeOrig = {
  primary: "#306d8a",
  secondary: "#16bbe5",
  accent: "#90d7ec",
};

const themeAdj = {
  primary: "#16bbe5",
  secondary: "#2A53E9",
  accent: "#11EE4B",
};

const themeAdj2 = {
  primary: "#2A53E9",
  secondary: "#6227E9",
  accent: "#166bbe5",
};

const themeTri = {
  primary: "#16bbe5",
  secondary: "#FF2D12",
  accent: "#FFB712",
};

// TODO: Revise Light & Dark theme colors
// TODO: Review Acelerex Color Scheme/Pallette in general

Vue.use(Vuetify);
export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    themes: {
      light: themeOrig,
      dark: themeOrig,
    },
  },
});
