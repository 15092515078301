/* eslint-disable no-useless-constructor */
/* eslint-disable no-alert */

import feathersClient, { BaseModel, makeServicePlugin } from "../feathers-client";

class Users extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "Users";
}

const servicePlugin = makeServicePlugin({
  Model: Users,
  service: feathersClient.service("users"),
  extend({ store }) {
    return {
      actions: {
        // eslint-disable-next-line no-unused-vars
        getAcelerexUser({ dispatch }, auth0Id) {
          return store
            .dispatch("users/find", {
              query: { user_id: auth0Id },
            })
            .then((r) => {
              return r.data[0];
            });
        },
      },
    };
  },
});

export default servicePlugin;
