/* eslint-disable no-useless-constructor */
import feathersClient, { BaseModel, makeServicePlugin } from "../../feathers-client";

class CHILELocationalMarginalPrices extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "CHILELocationalMarginalPrices";
}

const servicePlugin = makeServicePlugin({
  Model: CHILELocationalMarginalPrices,
  service: feathersClient.service("data-services/chile/locational-marginal-prices"),
  idField: "id",
  nameStyle: "path",
  getters: {},
});

export default servicePlugin;
