/* eslint-disable no-useless-constructor */
import feathersClient, { BaseModel, makeServicePlugin } from "../../feathers-client";

class ISONERealTimePrices extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "ISONERealTimePrices";
}

const servicePlugin = makeServicePlugin({
  Model: ISONERealTimePrices,
  service: feathersClient.service("data-services/isone/five-min-locational-marginal-prices"),
  nameStyle: "path",
  getters: {},
});

export default servicePlugin;
