/* eslint-disable no-useless-constructor */
import feathersClient, { BaseModel, makeServicePlugin } from "../../feathers-client";

class ReleaseNotes extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "ReleaseNotes";
}

const servicePlugin = makeServicePlugin({
  Model: ReleaseNotes,
  service: feathersClient.service("release-notes/app"),
  idField: "name",
  nameStyle: "path",
});

export default servicePlugin;
