import Vue from "vue";
import DefaultLayout from "@/layouts/default.vue";
import Error from "@/layouts/error.vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import Utils from "@/plugins/utils";
import vuetify from "@/plugins/vuetify";
import notion from "@/plugins/notion";
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css"; // Re-uses images from ~leaflet package
import "leaflet-defaulticon-compatibility";

// Import the plugin here
import { Auth0Plugin } from "./auth";

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  onRedirectCallback: (appState) => {
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  },
});

Vue.component("defaultLayout", DefaultLayout);
Vue.component("errorLayout", Error);

Vue.use(vuetify);
Vue.use(notion);

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

Vue.use(Utils);
