/* eslint-disable no-useless-constructor */
/* eslint-disable no-alert */

import { CaseModel } from "data-dictionary";
import download from "downloadjs";
import getUrl from "@/plugins/getUrl";
import { discard } from "feathers-hooks-common";
import feathersClient, { BaseModel, makeServicePlugin } from "../feathers-client";
import { getInstance } from "../../auth/index";

class Cases extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "Cases";
}

const servicePlugin = makeServicePlugin({
  Model: Cases,
  service: feathersClient.service("cases"),
  instanceDefaults(data) {
    if (typeof data.settings === "string") {
      // eslint-disable-next-line no-param-reassign
      data.settings = JSON.parse(data.settings);
    }
    const Case = new CaseModel(data);
    // console.debug('instanceDefault', data, Case);
    return Case;
  },
  preferUpdate: true,
  state: { latestCase: null },
  getters: {
    latestCase(state) {
      return state.latestCase;
    },
    loadPending(state) {
      return state.isGetPending || state.isFindPending;
    },
    savePending(state) {
      return state.isUpdatePending || state.isPatchPending;
    },
  },
  mutations: {
    setLatestCase(state, val) {
      state.latestCase = val;
    },
  },
  actions: {
    async downloadOutputs({ getters /* rootGetters */ }, id = null) {
      const caseId = id;
      // eslint-disable-next-line no-underscore-dangle
      const _case = getters.get(caseId);
      /* const scenario = rootGetters["scenarios/get"](_case.scenario_id);
      const scenarioName = scenario.name; */

      // Note: Feathers-client doesn't handle file downloads too well...

      // Get token
      const authService = getInstance();
      const token = await authService.getTokenSilently();
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      return fetch(`${getUrl()}/cases/${caseId}?download=true`, {
        headers,
      })
        .then((res) => {
          console.debug(res);
          return res.blob();
        })
        .then((blob) => {
          console.debug(blob);
          return download(blob, `${_case.name}.zip`, "application/zip");
        });
    },
    async confirmRemove({ dispatch }, id) {
      const itemId = id;
      const caseItem = await dispatch("get", itemId);
      // eslint-disable-next-line no-alert
      if (window.confirm(`Are you sure you want to delete this case: ${caseItem.name}?`)) {
        return dispatch("remove", itemId);
      }
      return false;
    },
  },
});

feathersClient.service("cases").hooks({
  before: {
    update: [discard("jobs")],
    patch: [discard("jobs")],
  },
});

export default servicePlugin;
