import Vue from "vue";
import Vuex from "vuex";
import { FeathersVuex } from "./feathers-client";

Vue.use(Vuex);
Vue.use(FeathersVuex);

const requireModule = require.context(
  // The path where the service modules live
  "./services",
  // Whether to look in subfolders
  true,
  // Only include .js files (prevents duplicate imports`)
  /.js$/
);
const servicePlugins = requireModule.keys().map((modulePath) => requireModule(modulePath).default);

export default new Vuex.Store({
  strict: process.env.NODE_ENV === "development",
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  plugins: [...servicePlugins],
});
