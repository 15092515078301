/* eslint-disable no-useless-constructor */
import feathersClient, { BaseModel, makeServicePlugin } from "../../feathers-client";

class NYISODayAheadPrices extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "NYISODayAheadPrices";
}

const servicePlugin = makeServicePlugin({
  Model: NYISODayAheadPrices,
  service: feathersClient.service(
    "data-services/nyiso/day-ahead-locational-marginal-prices-generation"
  ),
  idField: "_id",
  nameStyle: "path",
  getters: {},
});

export default servicePlugin;
