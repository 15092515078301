/* eslint-disable no-useless-constructor */
/* eslint-disable no-alert */

import feathersClient, { BaseModel, makeServicePlugin } from "../feathers-client";

class Jobs extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "Jobs";
}

const servicePlugin = makeServicePlugin({
  Model: Jobs,
  service: feathersClient.service("jobs"),
  state: {},
  getters: {
    loadPending(state) {
      return state.isGetPending || state.isFindPending;
    },
  },
  actions: {},
});

export default servicePlugin;
