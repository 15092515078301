/* eslint-disable no-useless-constructor */
/* eslint-disable no-alert */

import feathersClient, { BaseModel, makeServicePlugin } from "../feathers-client";

class Wiki extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "Wiki";
}

const servicePlugin = makeServicePlugin({
  Model: Wiki,
  service: feathersClient.service("wiki"),
  skipRequestIfExists: true,
  // eslint-disable-next-line no-unused-vars
  extend({ store, module }) {
    return {
      state: {},
      getters: {
        loadPending(state) {
          return state.isGetPending || state.isFindPending;
        },
        getImage(state, getters) {
          return (id) => {
            const block = getters.get(id);
            const url = block?.image?.file?.url ?? block?.page?.cover?.file?.url;
            if (url == null) return null;
            return url;
          };
        },
      },
      actions: {
        // eslint-disable-next-line no-unused-vars
        getImage({ dispatch, getters }, id) {
          dispatch("get", id);
          return getters.getImage(id);
        },
        getSidebar({ dispatch, getters }, id) {
          const sidebarId = `sidebar_${id}`;
          const sidebar = getters.get(sidebarId);
          if (sidebar) {
            console.debug("getter sidebar", sidebar);
            return Promise.resolve(sidebar);
          }
          return dispatch("get", [sidebarId, { query: { sidebar: true } }])
            .then((res) => {
              console.debug("got sidebar", res);
              return res;
            })
            .catch((err) => {
              console.debug("did not got sidebar", err);
            });
        },
      },
    };
  },
});

export default servicePlugin;
