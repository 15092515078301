/* eslint-disable no-useless-constructor */
import feathersClient, { BaseModel, makeServicePlugin } from "../../feathers-client";

class CAISORealTimePrices extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "CAISORealTimePrices";
}

const servicePlugin = makeServicePlugin({
  Model: CAISORealTimePrices,
  service: feathersClient.service("data-services/caiso/real-time-locational-marginal-prices"),
  idField: "_id",
  nameStyle: "path",
  getters: {},
});

export default servicePlugin;
