/* eslint-disable no-param-reassign */
const deepClone = (obj) => {
  if (obj === undefined) throw Error("Cannot clone undefined object");
  if (obj === null) console.debug("Cloning null object");
  return JSON.parse(JSON.stringify(obj));
};
const kebabCase = (string) =>
  string
    .replace(/([A-Z])([A-Z])/g, "$1-$2")
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/[\s_]+/g, "-")
    .toLowerCase();

const camelToTitleCase = (string) => {
  const separeteWords = string.replace(/([A-Z])/g, " $1");
  return separeteWords.charAt(0).toUpperCase() + separeteWords.slice(1);
};

const titleCase = (sentence) => sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase();

const camelToPascal = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const snakeCase = (e) =>
  e.match(/([A-Z])/g)
    ? e
        .match(/([A-Z])/g)
        .reduce((str, c) => str.replace(new RegExp(c), `_${c.toLowerCase()}`), e)
        .substring(e.slice(0, 1).match(/([A-Z])/g) ? 1 : 0)
    : e;

const camelCase = (string) =>
  string.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return "";
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });

const snakeToCamelCase = (string) => camelCase(string.replace(/_/g, " "));

const snakeToTitleCase = (string) =>
  string
    .replace(/_/g, " ")
    .split(" ")
    .reduce((acc, word) => `${acc} ${word.charAt(0).toUpperCase()}${word.slice(1)}`, "");

const getIfExists = (obj, fields, base = null) => {
  if (obj != null) {
    if (fields.length === 0) return obj;
    const field = fields.shift();
    return getIfExists(obj[field], fields, base);
  }
  return base;
};

const pathBuilder = (obj, path, message) => {
  const key = path.shift();
  if (!path.length) {
    if (obj[key] == null) {
      obj[key] = { messages: [message] };
    } else if (obj[key].messages == null || !Array.isArray(obj[key].messages)) {
      obj[key].messages = [message];
    } else {
      obj[key].messages.push(message);
    }
    return obj;
  }

  if (obj[key] == null) {
    obj[key] = {};
  }

  return pathBuilder(obj[key], path, message);
};

const range = (size, start = 0) => [...Array(size).keys()].map((i) => i + start);

const chartColors = [
  "#1976d2",
  "#7f70d6",
  "#bd66cb",
  "#ec5bb1",
  "#ff5a8f",
  "#ff6a67",
  "#ff863e",
  "#ffa600",
];

const getChartColor = (i) => chartColors[i % chartColors.length];

/* Alternatives
red: 'rgb(255, 99, 132)',
orange: 'rgb(255, 159, 64)',
	yellow: 'rgb(255, 205, 86)',
	green: 'rgb(75, 192, 192)',
	blue: 'rgb(54, 162, 235)',
	purple: 'rgb(153, 102, 255)',
  grey: 'rgb(201, 203, 207)'
  */

const validation = {
  required: (value) => !!value || "Required.",
  counter: (length) => (value) => value.length <= length || `Max ${length} characters.`,
  specialCharacters: (value) =>
    !/[/\\:*?<>|"']/g.test(value) ||
    "Name can't contain any of the following characters: \\/:*?<>|\"'",
};

const filterLowChartValues = (limit = 0) => {
  return (arr) => arr.y.reduce((acc, item) => (item < 0 ? acc - item : acc + item), 0) > limit;
};
const chartDownloadName = (caseName, outputType, chartTitle) => {
  return {
    format: "png",
    filename: `${caseName}_${outputType}${chartTitle ? `_${chartTitle}` : ""}`,
  };
};

const globalFunctions = {
  deepClone,
  kebabCase,
  camelToTitleCase,
  camelCase,
  snakeCase,
  camelToPascal,
  snakeToTitleCase,
  snakeToCamelCase,
  titleCase,
  getIfExists,
  pathBuilder,
  getChartColor,
  range,
};
const Utils = {
  install(Vue) {
    Vue.prototype.$utils = {
      ...globalFunctions,
      chartColors,
      validation,
    };

    Vue.prototype.$console = console;

    Vue.filter("replaceUnderscore", (value, char = " ") =>
      value ? value.replace(/_/g, char) : value
    );
    Vue.filter("toProperCase", (value) =>
      value
        ? value.replace(
            /\w\S*/g,
            (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
          )
        : value
    );
    Vue.filter("formatNumber", (number) => Number(number).toLocaleString());
    Vue.filter("dateToString", (value) =>
      (value instanceof Date ? value.toString() : new Date(value).toString()).substring(0, 24)
    );
  },
};

export default Utils;
export {
  deepClone,
  kebabCase,
  camelToTitleCase,
  camelCase,
  snakeCase,
  snakeToCamelCase,
  titleCase,
  snakeToTitleCase,
  camelToPascal,
  filterLowChartValues,
  chartDownloadName,
};
