import feathers from "@feathersjs/feathers";
import rest from "@feathersjs/rest-client";
import { batchClient } from "feathers-batch";
import feathersVuex from "feathers-vuex";
import getUrl from "../plugins/getUrl";

const restClient = rest(getUrl()).fetch(window.fetch);

const transport = restClient;
const feathersClient = feathers()
  .configure(transport)
  .configure(batchClient({ batchService: "batch", exclude: ["authentication"] }));

export default feathersClient;

const { BaseModel, makeServicePlugin, FeathersVuex } = feathersVuex(feathersClient, {
  serverAlias: "api",
});

export { makeServicePlugin, BaseModel, FeathersVuex };
