<template>
  <v-app>
    <v-app-bar app flat clipped-left dense>
      <v-menu v-if="isAuthenticated" transition="slide-y-transition" offset-y bottom>
        <template #activator="{ on }">
          <v-app-bar-nav-icon class="hidden-sm-and-up" v-on="on"> </v-app-bar-nav-icon>
        </template>
        <v-list>
          <v-list-item v-for="item in leftNavMenu" :key="item.title" :to="item.to">
            <v-list-item-content>
              <v-list-item-title
                >{{ item.title
                }}<v-chip v-if="item.chip" color="accent">
                  {{ item.chip }}
                </v-chip></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-toolbar-title>
        <a href="/">
          <img height="48" src="@/assets/acelerex-logo.png" />
        </a>
      </v-toolbar-title>

      <template v-if="isAuthenticated">
        <v-toolbar-items class="hidden-xs-only">
          <v-btn v-for="item in leftNavMenu" :key="item.title" :to="item.to" exact depressed ripple
            >{{ item.title }}
            <v-chip v-if="item.chip" color="accent">
              {{ item.chip }}
            </v-chip>
          </v-btn>
        </v-toolbar-items>
        <v-spacer />
        <v-btn exact depressed ripple icon replace :to="caseVis">
          <v-icon>public</v-icon>
        </v-btn>
        <AppMenu />
        <v-menu v-if="isAuthenticated" bottom min-width="200px" rounded offset-y>
          <template #activator="{ on }">
            <v-btn icon v-on="on">
              <v-avatar class="primary white--text" left size="35">
                <img v-if="currentUser.picture" :src="currentUser.picture" />
              </v-avatar>
            </v-btn>
          </template>
          <v-card>
            <v-list-item-content class="justify-center">
              <div class="mx-auto text-center">
                <v-avatar class="primary white--text" color="primary" left>
                  <img v-if="currentUser.picture" :src="currentUser.picture" />
                </v-avatar>
                <p class="text-caption mt-1">
                  {{ currentUser.email }}
                </p>
                <v-divider class="my-3"></v-divider>
                <v-btn depressed rounded text @click.stop="logout">
                  <v-icon>mdi-logout</v-icon>
                  Logout
                </v-btn>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>
      </template>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer app padless color="secondary">
      <v-row justify="center" dense>
        <v-col class="text-center white--text" cols="12">
          <strong>Acelerex v{{ version }}</strong> &mdash; &copy;2017-{{ currentYear }}
          Acelerex Inc.
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "App",
  components: {
    AppMenu: () => import("@/components/AppMenu.vue"),
  },
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      lastUpdatedCase: null,
    };
  },
  computed: {
    ...mapState({
      // getUsername: (state) => (state.auth.user ? state.auth.user.email : null),
      // currentScenarioId: (state) => state.scenarios.currentId,
    }),
    ...mapGetters({
      latestCase: "cases/latestCase",
    }),
    isAuthenticated() {
      return this.$auth.isAuthenticated;
    },
    currentUser() {
      return this.$auth.user;
    },
    currentYear() {
      return new Date().getFullYear();
    },
    leftNavMenu() {
      return [
        {
          title: "Cases",
          to: this.directoryOverview,
        },
        {
          title: "Deltas",
          to: { name: "Deltas" },
        },
        (() => {
          // TODO: Use a real feature flag:
          // See https://docs.gitlab.com/ee/operations/feature_flags.html
          // and https://github.com/Unleash/unleash
          const { hostname } = window.location;
          return hostname.includes("consulting") ||
            hostname.includes("localhost") ||
            hostname.includes("demo") ||
            hostname.includes("analytics")
            ? {
                title: "Forecasts",
                to: { name: "Forecasts" },
              }
            : {};
        })(),
        {
          title: "Jobs",
          to: { name: "Jobs" },
        },
      ].filter((e) => e.title != null);
    },
    directoryOverview() {
      const route = {
        name: "Folders",
      };
      if (this.$route.params.folderId) {
        route.params = { folderId: this.$route.params.folderId };
      }
      return route;
    },
    caseVis() {
      const { latestCase } = this;
      if (!latestCase && !this.lastUpdatedCase) this.getLatestCase();
      const targetCase = latestCase || this.lastUpdatedCase;
      return targetCase
        ? {
            name: "CaseVis",
            params: { id: targetCase.id, scenario: targetCase.scenario_id },
          }
        : {};
    },
  },
  methods: {
    ...mapActions({ findCase: "cases/find" }),
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
    getLatestCase() {
      return this.findCase({
        query: {
          $sort: { updated: -1 },
          $limit: 1,
          $select: ["id", "updated", "scenario_id"],
        },
      }).then((res) => {
        [this.lastUpdatedCase] = res.data;
      });
    },
  },
};
</script>

<style>
.flex > .v-subheader {
  height: auto;
}

.v-data-table table tbody tr.v-data-table__expanded__content {
  box-shadow: none;
  -webkit-box-shadow: none;
}
</style>
