/* eslint-disable no-useless-constructor */
import feathersClient, { BaseModel, makeServicePlugin } from "../../feathers-client";

class PJMNode extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "PJMNode";
}

const servicePlugin = makeServicePlugin({
  Model: PJMNode,
  service: feathersClient.service("data-services/pjm/nodes"),
  idField: "Pricing Node ID",
  nameStyle: "path",
  getters: {},
});

export default servicePlugin;
