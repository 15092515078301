/* eslint-disable no-useless-constructor */
import feathersClient, { BaseModel, makeServicePlugin } from "../../feathers-client";

class ISONENode extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "ISONENode";
}

const servicePlugin = makeServicePlugin({
  Model: ISONENode,
  service: feathersClient.service("data-services/isone/nodes"),
  idField: "Location ID",
  nameStyle: "path",
  getters: {},
});

export default servicePlugin;
