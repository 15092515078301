/* eslint-disable no-useless-constructor */
/* eslint-disable no-alert */
import download from "downloadjs";
import getUrl from "@/plugins/getUrl";
import feathersClient, { BaseModel, makeServicePlugin } from "../feathers-client";

class Messages extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "Messages";
}

const servicePlugin = makeServicePlugin({
  Model: Messages,
  service: feathersClient.service("messages"),
  actions: {
    download({ rootState, rootGetters }, id = null) {
      const caseId = id;
      // eslint-disable-next-line no-underscore-dangle
      const _case = rootGetters["cases/get"](caseId);

      // Note: Feathers-client doesn't handle file downloads too well...
      const headers = {
        Authorization: rootState.auth.accessToken,
      };
      return fetch(`${getUrl()}/messages/?case_id=${caseId}&csv=true&all=true`, {
        headers,
      })
        .then((res) => {
          console.debug(res);
          return res.blob();
        })
        .then((blob) => {
          console.debug(blob);
          return download(blob, `${_case.name}.log.csv`, "text/csv");
        });
    },
  },
});

export default servicePlugin;
