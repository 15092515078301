/* eslint-disable no-useless-constructor */
import feathersClient, { BaseModel, makeServicePlugin } from "../../feathers-client";

class SPPDayAheadPrices extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "SPPDayAheadPrices";
}

const servicePlugin = makeServicePlugin({
  Model: SPPDayAheadPrices,
  service: feathersClient.service("data-services/spp/day-ahead-locational-marginal-prices"),
  nameStyle: "path",
  getters: {},
});

export default servicePlugin;
