import Vue from "vue";
import Router from "vue-router";
import authenticationGuard from "@/auth/authenticationGuard";

Vue.use(Router);

function paramToInt(route) {
  const obj = route.params;
  Object.keys(route.params).forEach((e) => {
    if (e !== "folderId") {
      const num = Number.parseInt(route.params[e], 10);
      if (!Number.isNaN(num)) obj[e] = num;
    }
  });
  return obj;
}

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/403-Forbidden",
      meta: { layout: "error", error: { statusCode: 403 } },
    },
    {
      path: "/automation",
      beforeEnter: () => {
        window.open("https://automation.acelerex.com", "_self");
      },
    },
    {
      path: "/data-services",
      beforeEnter: () => {
        window.open("https://dataservices.acelerex.com", "_self");
      },
    },
    {
      path: "/portal",
      beforeEnter: () => {
        const url = `https://portal${
          process.env.NODE_ENV === "production" ? "" : ".staging"
        }.acelerex.com`;
        window.open(url, "_self");
      },
    },
    {
      path: "/wiki/:id?",
      name: "Wiki",
      meta: { requiresAuth: true },
      component: () => import(/* webpackChunkName: "Wiki" */ "@/views/Wiki.vue"),
      // beforeEnter: () => {
      //   const url = `${window.location.protocol}//${window.location.host}/wiki/`;
      //   window.open(url, "_blank");
      // },
    },
    {
      name: "Homepage",
      path: "",
      meta: { requiresAuth: true },
      component: () => import(/* webpackChunkName: "Homepage" */ "@/views/Index.vue"),
    },
    {
      name: "Jobs",
      path: "/jobs",
      meta: { requiresAuth: true },
      component: () => import(/* webpackChunkName: "Deltas" */ "@/views/JobOverview.vue"),
    },
    {
      name: "Deltas",
      path: "/deltas",
      meta: { requiresAuth: true },
      component: () => import(/* webpackChunkName: "Deltas" */ "@/views/Deltas.vue"),
    },
    {
      name: "Forecasts",
      path: "/forecasts",
      meta: { requiresAuth: true },
      component: () => import(/* webpackChunkName: "IHR" */ "@/views/Forecasts.vue"),
    },
    {
      name: "Folders",
      path: "/folders/:folderId?",
      meta: { requiresAuth: true },
      component: () => import(/* webpackChunkName: "Folders" */ "@/views/Folders.vue"),
    },
    {
      path: "/folders/:folderId?/case/:id",
      alias: "/case/:id",
      props: paramToInt,
      component: () => import(/* webpackChunkName: "Cases" */ "@/views/CaseView.vue"),
      children: [
        {
          name: "CaseInput",
          path: "input",
          alias: "",
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "Cases" */ "@/views/CaseInput.vue"),
        },
        {
          name: "CaseOutput",
          path: "output",
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "CaseOutput" */ "@/views/CaseOutput.vue"),
        },
        {
          name: "CaseVis",
          path: "visualization",
          meta: { requiresAuth: true },
          component: () => import(/* webpackChunkName: "CaseVis" */ "@/views/CaseVis.vue"),
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth) || to.name === "login") {
    authenticationGuard(to, from, next);
  } else {
    next();
  }
});

router.onError((e) => {
  if (e.name === "Forbidden") {
    router.push("/403-Forbidden");
  }
});

export default router;
