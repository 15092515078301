/* eslint-disable no-useless-constructor */
/* eslint-disable no-alert */

import feathersClient, { BaseModel, makeServicePlugin } from "../feathers-client";

class Markets extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "Markets";
}

const servicePlugin = makeServicePlugin({
  Model: Markets,
  service: feathersClient.service("markets"),
  getters: {
    loadPending(state) {
      return state.isGetPending || state.isFindPending;
    },
  },
});

export default servicePlugin;
