/* eslint-disable no-useless-constructor */
/* eslint-disable no-alert */

import { alterItems } from "feathers-hooks-common";
import feathersClient, { BaseModel, makeServicePlugin } from "../feathers-client";

class Outputs extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "Outputs";
}

const servicePlugin = makeServicePlugin({
  Model: Outputs,
  service: feathersClient.service("outputs"),
  getters: {
    loadPending(state) {
      return state.isGetPending || state.isFindPending;
    },
  },
});

feathersClient.service("outputs").hooks({
  after: {
    all: [
      alterItems(async (item) => {
        if (!item.type_name) {
          const job = await feathersClient.service("jobs").get(item.id);

          return { ...item, type_name: job.type_name };
        }

        return item;
      }),
    ],
  },
});

export default servicePlugin;
