/* eslint-disable no-param-reassign */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-alert */
import feathersClient, { BaseModel, makeServicePlugin } from "../feathers-client";
import { getInstance } from "../../auth/index";

class Batch extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = "Batch";
}

const servicePlugin = makeServicePlugin({
  Model: Batch,
  service: feathersClient.service("batch"),
});

feathersClient.service("batch").hooks({
  before: {
    all: [
      async (ctx) => {
        const authService = getInstance();
        const token = await authService.getTokenSilently();
        ctx.params.accessToken = token;
        ctx.params.headers = { Authorization: `Bearer ${token}` };
        return ctx;
      },
    ],
  },
});

export default servicePlugin;
