// import Vue from "vue";
import VueKatex from "vue-katex";
import "prismjs";
import "prismjs/themes/prism.css";
import "katex/dist/katex.min.css";
import "vue-notion/src/styles.css";

import { getPageBlocks as blocks, getPageTable as table } from "vue-notion";

const ENDPOINT_URL = "https://notion.acelerex.workers.dev/v1";
export const getPageBlocks = (id) => blocks(id, ENDPOINT_URL);
export const getPageTable = (id) => table(id, ENDPOINT_URL);

export default VueKatex;
