<template>
  <v-app>
    <v-app-bar flat app clipped-left dense>
      <v-toolbar-title>
        <a href="http://acelerex.com">
          <img height="48" src="@/assets/acelerex-logo.png" />
        </a>
      </v-toolbar-title>
      <v-spacer />
      <v-menu v-if="isAuthenticated" bottom min-width="200px" rounded offset-y>
        <template #activator="{ on }">
          <v-btn icon v-on="on">
            <v-avatar class="primary white--text" left size="35">
              <img v-if="currentUser.picture" :src="currentUser.picture" />
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar class="primary white--text" color="primary" left>
                <img v-if="currentUser.picture" :src="currentUser.picture" />
              </v-avatar>
              <p class="text-caption mt-1">
                {{ currentUser.email }}
              </p>
              <v-divider class="my-3"></v-divider>
              <v-btn depressed rounded text @click.stop="logout">
                <v-icon>mdi-logout</v-icon>
                Logout
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-app-bar>
    <v-main>
      <v-container class="pt-6 mt-12">
        <v-row justify="center">
          <v-img src="@/assets/gandalf.png" max-width="200" />
          <v-col cols="12" class="text-center">
            <h2 class="text-h4 my-4">You do not have a license to use this software</h2>
            <p>403 Forbidden</p>
            <v-btn outlined color="primary" to="/portal">Go to Acelerex Portal</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer app color="secondary" max-height="32px">
      <v-row justify="center">
        <v-col class="text-center white--text text-body-2 pt-0" cols="12">
          <strong>Dispatcher v{{ version }}</strong>
          &mdash; &copy;2017 - {{ new Date().getFullYear() }}
          Acelerex Inc.
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "Error",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
    };
  },
  computed: {
    isAuthenticated() {
      return this.$auth.isAuthenticated;
    },
    currentUser() {
      return this.$auth.user;
    },
  },
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
  },
};
</script>

<style></style>
